// PrivacyPolicy.js
import React, { useState } from 'react';
import './PrivacyPolicy.css';
import TopBar from '../../components/topbar/TopBar.js';

const PrivacyPolicy = () => {
    // Define your privacy policy items
    const policyItems = [
        {
            title: 'Location Services',
            content:
                'We collect your location data solely for the purpose of matching you with potential matches nearby. Location data is only gathered when you enable the "Ready to Match" feature. If this feature is turned off, no location data will be collected.',
        },
        {
            title: 'Camera Access',
            content:
                'Granting access to your camera enables you to upload photos and videos directly through the app. This allows you to set your profile picture and share images or videos in chats with your matches.',
        },
        {
            title: 'Data Security',
            content:
                'We implement robust security measures to protect your personal information from unauthorized access, ensuring your data remains safe and confidential.',
        },
        {
            title: 'Deleting Your Account',
            content:
                'You can delete your account at any time by going to the settings page and selecting the "Delete your profile" option. This will permanently remove all your data from our servers. If you have more questions, please contact us at https://mytgroup.ca/contactus ',
        },
        {
            title: 'Our Commitment to Child Safety',
            content:
                'Myt maintains a zero-tolerance policy toward child sexual abuse and exploitation (CSAE). We are firmly committed to protecting minors from any form of sexual abuse, harassment, grooming, exploitation, or trafficking. We strictly prohibit the use of our platform to produce, share, access, or distribute child sexual abuse material (CSAM) or to engage in any activity that exploits or endangers children. All users must comply with applicable child protection laws. We report any suspected CSAE to the relevant authorities, including the National Center for Missing & Exploited Children (NCMEC). If you encounter any content or behavior that may be related to CSAE, please report it immediately through our in-app reporting system or by contacting us through our form https://mytgroup.ca/contactus. We reserve the right to suspend or ban any account found violating these standards.',
        }

        // {
        //   title: 'Third-Party Services',
        //   content:
        //     'We may share certain information with trusted third-party services to deliver enhanced functionalities and features within the app.',
        // },
        // Add more policy items as needed
    ];

    // State to keep track of which item is expanded
    const [expandedIndex, setExpandedIndex] = useState(null);

    // Toggle function to expand/collapse items
    const toggleItem = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="PrivacyPolicy">
            <TopBar />
            <div className="PrivacyPolicy-content">
                <h1 className="PrivacyPolicy-title">Privacy Policy</h1>
                <p className="PrivacyPolicy-parargraph">
                    Welcome to the Myt Group Privacy Policy. At Myt Group,
                    we believe dating should be fun and exciting, but safety is our top priority.
                    Below, you'll find details on how we protect your personal information and ensure your privacy.
                </p>
                <ol className="PrivacyPolicy-list">
                    {policyItems.map((item, index) => (
                        <li key={index} className="PrivacyPolicy-listItem">
                            <div
                                className="PrivacyPolicy-itemHeader"
                                onClick={() => toggleItem(index)}
                            >
                                <span className="PrivacyPolicy-itemNumber">{index + 1}.</span>
                                <span className="PrivacyPolicy-itemTitle">{item.title}</span>
                                <span className="PrivacyPolicy-toggleIcon">
                                    {expandedIndex === index ? '-' : '+'}
                                </span>
                            </div>
                            {expandedIndex === index && (
                                <div className="PrivacyPolicy-itemContent">
                                    {item.content}
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
